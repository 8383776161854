/* eslint-disable eqeqeq */
import {DateTime, DateTimeJSOptions, DurationLike, Interval} from 'luxon';
import {BillingPeriodDateFormat} from '../../biller-config';
import {err} from '../../core';
import {AbsoluteDate, DateDurationLike} from './AbsoluteDate';
import {getNextAbsoluteDate} from './frequency';

export const WEB_CONTEXT: DateContext = {timezone: 'BROWSER_DEFAULT'} as const;
export type DateContext =
  | {
      now?: Date;
      timezone: string | 'BROWSER_DEFAULT';
      // Prevent passing in biller config directly to date context
      billerId?: never;
    }
  | {
      now?: Date;
      billerConfig: {
        timezone: string;
      };
    };

export const getOptions = (context: DateContext): DateTimeJSOptions => {
  let timezone: string | undefined;
  if ('timezone' in context) {
    timezone = context.timezone;
  } else {
    timezone = context.billerConfig.timezone;
  }
  if (timezone === WEB_CONTEXT.timezone) {
    // Let Luxon use the browser's timezone in the browser
    return {};
  }

  if (!timezone) {
    throw new Error('No timezone provided in context');
  }
  return {zone: timezone};
};

export const getNow = (context: DateContext) => {
  if (context.now) {
    // In some scenarios we pass "now" as a DateTime and this may be shadowed
    // due to structural typing so we first ensure the date is _actually_ a
    // "Date"
    return DateTime.fromJSDate(toDate(context.now), getOptions(context));
  }
  return DateTime.local(getOptions(context));
};

export const now = (context: DateContext) => getNow(context);

export const fromFormat = (
  formats: string[],
  date: string,
  context: DateContext | null
) => {
  for (const format of formats) {
    const parsedDate = DateTime.fromFormat(
      date,
      format,
      context ? getOptions(context) : undefined
    );

    if (parsedDate.isValid) {
      return parsedDate;
    }
  }
  throw new Error(
    `Invalid date format ${date}. Must be one of [${formats.join(', ')}]`
  );
};

export function toDate<D extends Date | DateTime | null | string | undefined>(
  date: D
): undefined extends D
  ? Date | undefined
  : null extends D
    ? Date | null
    : Date {
  if (typeof date === 'string') {
    return new Date(date);
  }
  if (date instanceof DateTime) {
    return date.toJSDate();
  }
  return date as any;
}

export function toDateTime<
  D extends Date | AbsoluteDate | DateTime | null | string | undefined,
>(
  date: D,
  context: DateContext
): undefined extends D
  ? DateTime | undefined
  : null extends D
    ? DateTime | null
    : DateTime {
  if (typeof date === 'string') {
    return DateTime.fromISO(date, getOptions(context));
  }
  if (date instanceof DateTime) {
    return date;
  }

  if (date instanceof AbsoluteDate) {
    return date.toDateTime(context);
  }

  return (date ? DateTime.fromJSDate(date, getOptions(context)) : date) as any;
}

export function fieldSorter<X>(
  getTime: (x: X) => DateTime | Date | AbsoluteDate | null | undefined,
  args?: {
    dir?: 'ASC' | 'DESC';
    nulls?: 'FIRST' | 'LAST';
  }
) {
  return function timeSorter(rawA: X, rawB: X) {
    let aMS: number;
    let bMS: number;
    const a = getTime(rawA);
    const b = getTime(rawB);
    if (a == null) {
      return args?.nulls === 'FIRST' ? -1 : 1;
    }
    if (b == null) {
      return args?.nulls === 'FIRST' ? 1 : -1;
    }
    if (a instanceof AbsoluteDate) {
      aMS = a.toComparable();
    } else if ('getTime' in a) {
      aMS = a.getTime();
    } else {
      aMS = a.toMillis();
    }
    // NOTE: Do not compare AbsoluteDate with non-AbsoluteDate fields
    if (b instanceof AbsoluteDate) {
      bMS = b.toComparable();
    } else if ('getTime' in b) {
      bMS = b.getTime();
    } else {
      bMS = b.toMillis();
    }
    if (args?.dir === 'ASC') {
      return aMS - bMS;
    } else {
      return bMS - aMS;
    }
  };
}

export const min = <T extends Date | DateTime>(...dates: T[]) =>
  dates.reduce((min, date) => (date < min ? date : min));

export const max = <T extends Date | DateTime>(...dates: T[]) =>
  dates.reduce((max, date) => (date > max ? date : max));

export function sorter(args: Parameters<typeof fieldSorter>[1]) {
  return fieldSorter(
    (x: DateTime | Date | AbsoluteDate | null | undefined) => x,
    args
  );
}

interface GetNextUpcomingDateByFrequencyArgs {
  frequency: 'weekly' | 'fortnightly' | 'monthly' | 'quarterly';
  after: AbsoluteDate;
  anchor: AbsoluteDate;
}

export type DateFrequency =
  | 'weekly'
  | 'fortnightly'
  | 'monthly'
  | 'quarterly'
  | 'end_of_month';

const formatDate = (date: AbsoluteDate) => date.toFormat('yyyy-MMM-dd');

export const getFrequencyDuration = (
  frequency: DateFrequency
): DateDurationLike => {
  switch (frequency) {
    case 'weekly':
      return {weeks: 1};
    case 'fortnightly':
      return {weeks: 2};
    case 'monthly':
      return {months: 1};
    case 'quarterly':
      return {quarters: 1};
    default:
      throw new Error(`Invalid frequency: ${frequency}`);
  }
};

export const generateDateSeries = (
  {
    start,
    frequency,
    end,
    inclusive = false,
  }: {
    start: AbsoluteDate;
    frequency: DateFrequency;
    end: AbsoluteDate;
    // Whether to include the start and end date as part of the range
    inclusive?: boolean;
  },
  context: DateContext
) => {
  const interval = Interval.fromDateTimes(
    start.toDateTime(context),
    end.toDateTime(context)
  );
  const dates: AbsoluteDate[] = inclusive ? [start] : [];

  if (start.isAfter(end)) {
    return new Error(
      `Start date "${formatDate(start)}" must be before "${formatDate(end)}"`
    );
  }

  let failSafe = 0;
  for (;;) {
    failSafe++;
    if (failSafe > 256) {
      return new Error('Failed to calculate instalments');
    }
    const nextIntervalDate =
      dates.length === 0
        ? start
        : getNextAbsoluteDate({
            anchor: dates[dates.length - 1],
            frequency,
          });

    if (interval.contains(nextIntervalDate.toDateTime(context))) {
      dates.push(nextIntervalDate);
    } else if (
      inclusive &&
      interval.end.equals(nextIntervalDate.toDateTime(context))
    ) {
      dates.push(nextIntervalDate);
    } else {
      break;
    }
  }

  return dates;
};

/**
 * Given the months list in LLL format, generate the date in cross-year forward manner.
 *
 * Eg:
 * ['feb', 'may'] => ['2024-02-29', '2024-05-31']
 * ['may', 'feb'] => ['2024-05-31', '2025-02-28']
 * ['may', 'feb', 'apr'] => ['2024-05-31', '2025-02-28', '2025-04-30']
 */

export const generateForwardDateSeries = (
  periods: BillingPeriodDateFormat[],
  context: DateContext
) => {
  if (!periods.length) {
    throw new Error('Billing period cannot be empty');
  }

  const dates: AbsoluteDate[] = [];
  const DATE_FORMAT = 'LLL';
  let currentIndexYear = now(context).year;

  periods.forEach((period, index) => {
    const currentPeriod = DateTime.fromFormat(
      period,
      DATE_FORMAT,
      getOptions(context)
    );

    if (!currentPeriod.isValid) {
      throw new Error(
        `${period} is not a valid date in the format of ${DATE_FORMAT}`
      );
    }

    if (index > 0) {
      const previousPeriod = AbsoluteDate.fromDateTime(
        DateTime.fromFormat(
          periods[index - 1],
          DATE_FORMAT,
          getOptions(context)
        )
      );

      if (AbsoluteDate.fromDateTime(currentPeriod).isBefore(previousPeriod)) {
        currentIndexYear = currentIndexYear + 1;
      }
    }

    dates.push(
      AbsoluteDate.fromDateTime(
        currentPeriod.set({
          year: currentIndexYear,
          day: currentPeriod.set({year: currentIndexYear}).endOf('month').day,
        })
      )
    );
  });

  return dates;
};

/**
 * Incredibly naive implementation of getting the next upcoming date by
 * frequency.
 */
export function getDateInSeries(
  {frequency, after: afterDate, anchor}: GetNextUpcomingDateByFrequencyArgs,
  context: DateContext
) {
  const after = AbsoluteDate.fromDateTime(toDateTime(afterDate, context));

  const series = generateDateSeries(
    {
      frequency,
      end: after.plus(getFrequencyDuration(frequency)),
      start: AbsoluteDate.fromDateTime(toDateTime(anchor, context)),
      inclusive: true,
    },
    context
  );
  if (err(series)) {
    throw series;
  }

  const next = series.find(date => date.isAfter(after));
  if (!next) {
    throw new Error(
      `Failed to find next date after "${after.toISO()}" for frequency "${frequency}"`
    );
  }

  return next;
}

export function isWithinDaysOf(
  days: number,
  a: AbsoluteDate | DateTime | Date,
  b: AbsoluteDate | DateTime | Date,
  context: DateContext
) {
  const aDT = toDateTime(a, context);
  const bDT = toDateTime(b, context);

  return Math.abs(aDT.diff(bDT, 'days').days) <= days;
}

export const getYesterdayDateRange = (context: DateContext) => {
  const yesterday = now(context).minus({days: 1});

  return {
    start: yesterday.startOf('day').toJSDate(),
    end: yesterday.endOf('day').toJSDate(),
    yesterday,
  };
};

export const getCalculatedDateRangeOrProvided = (
  dateString: string | undefined,
  plus: DurationLike,
  context: DateContext
) => {
  if (!dateString) {
    return getDailyRange(now(context).plus(plus));
  }

  const DATE_FORMAT = 'dd-MM-yyyy';

  const date = DateTime.fromFormat(
    dateString,
    DATE_FORMAT,
    getOptions(context)
  );
  if (!date.isValid) {
    throw new Error(
      `${dateString} is not a valid date in the format of ${DATE_FORMAT}`
    );
  }

  return getDailyRange(date);
};

export const getYesterdaysDateRangeOrProvided = (
  dateString: string | undefined,
  context: DateContext
) => getCalculatedDateRangeOrProvided(dateString, {days: -1}, context);

function getDailyRange(date: DateTime) {
  return {
    start: date.startOf('day').toJSDate(),
    end: date.endOf('day').toJSDate(),
    date: date,
  };
}

export function interpretCardExpiry(
  args: {year: number; month: number},
  context: DateContext
) {
  try {
    const {month} = args;
    const year = args.year < 2000 ? args.year + 2000 : args.year;

    const lastDayOfMonth = DateTime.fromObject(
      {
        month,
        year,
      },
      getOptions(context)
    )
      .endOf('month')
      .startOf('day');

    return lastDayOfMonth.toJSDate();
  } catch (e) {
    // Temporarily catch and return undefined during soft rollout
    console.error('Could not interpret card expiry', args, e);
    return undefined;
  }
}

type Digit = 0 | 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9;
type HourMinuteString = `${Digit}${Digit}:${Digit}${Digit}`;
export function isWithinHours(
  args: {
    start: HourMinuteString;
    end: HourMinuteString;
  },
  context: DateContext
) {
  const startDT = fromFormat(['HH:mm'], args.start, context);
  const endDT = fromFormat(['HH:mm'], args.end, context);

  if (startDT > endDT) {
    throw new Error('start must be before end');
  }
  const present = now(context);

  return present >= startDT && present <= endDT;
}

export function nextTimeWithinHours(
  args: {
    start: HourMinuteString;
    end: HourMinuteString;
  },
  context: DateContext
) {
  const present = now(context);

  const startDT = fromFormat(['HH:mm'], args.start, context);
  const endDT = fromFormat(['HH:mm'], args.end, context);

  if (startDT > endDT) {
    throw new Error('start must be before end');
  }

  if (endDT < present) {
    return startDT.plus({days: 1});
  }
  if (startDT > present) {
    return startDT;
  }

  return present;
}

type RangeLike = {
  start: AbsoluteDate;
  end: AbsoluteDate;
};

/**
 * A common problem is providing strict ownership of overlapping dates to a specific group.
 *
 * This is optimized for a low number of ranges
 *
 * This occurs with the balanced-pay-every-x and smooth-pay plan types.
 * Given a range of `2000-01-01->2000-01-01` and `2000-01-01->2000-01-02` and a list of dates
 * `2000-01-01`, `2000-01-02`
 *
 * We should expect only the first range to contain 2000-01-01 and the second range to contain 2000-01-02
 */
export const groupDatesIntoRanges = <T extends RangeLike>(
  args: {
    ranges: T[];
    dates: AbsoluteDate[];
  },
  context: DateContext
): {
  grouped: {
    range: T;
    dates: AbsoluteDate[];
  }[];
  outOfBounds: AbsoluteDate[];
} => {
  const {ranges, dates} = args;

  const grouped: [T, AbsoluteDate[]][] = [];

  const sortedRanges = [...ranges].sort(fieldSorter(x => x.end, {dir: 'ASC'}));
  const availableDates = dates
    .map(d => AbsoluteDate.fromDateTime(toDateTime(d, context)))
    .sort(sorter({dir: 'ASC'}));

  if (availableDates.length === 0) {
    return {
      grouped: ranges.map(range => ({range, dates: []})),
      outOfBounds: [],
    };
  }

  if (ranges.length === 0) {
    return {
      grouped: [],
      outOfBounds: availableDates.map(d =>
        AbsoluteDate.fromDateTime(toDateTime(d, context))
      ),
    };
  }

  return {
    grouped: sortedRanges.map(range => {
      const datesInBounds = availableDates.filter(date => {
        const dt = AbsoluteDate.fromDateTime(toDateTime(date, context));
        return (
          dt.isAfterOrEqual(
            AbsoluteDate.fromDateTime(toDateTime(range.start, context))
          ) &&
          dt.isBeforeOrEqual(
            AbsoluteDate.fromDateTime(toDateTime(range.end, context))
          )
        );
      });

      grouped.push([range, datesInBounds]);

      availableDates.splice(0, datesInBounds.length);
      return {
        range,
        dates: datesInBounds,
      };
    }),
    outOfBounds: availableDates,
  };
};
