import { AbsoluteDate } from 'payble-shared';
import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  AbsoluteDate: { input: AbsoluteDate; output: AbsoluteDate; }
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the 'date-time' format outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representation of dates and times using the Gregorian calendar. */
  DateTime: { input: Date | string; output: string; }
  /** A field whose value conforms to the standard internet email address format as specified in HTML Spec. */
  EmailAddress: { input: string; output: string; }
  /** Custom field for any valid JSON data */
  JSON: { input: any; output: any; }
  /** A field whose value conforms to the standard E.164 format as specified in: https://en.wikipedia.org/wiki/E.164. Basically this is +17895551234. */
  PhoneNumber: { input: string; output: string; }
  /** The Void scalar type represents no value being returned */
  Void: { input: void; output: void; }
};

export type Account = {
  __typename?: 'Account';
  amountOwing: Scalars['Float']['output'];
  collectionExemption?: Maybe<Scalars['String']['output']>;
  description: Scalars['String']['output'];
  externalId: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  issueDate?: Maybe<Scalars['AbsoluteDate']['output']>;
  meta: Scalars['JSON']['output'];
  targetDate?: Maybe<Scalars['AbsoluteDate']['output']>;
  type: Scalars['String']['output'];
};

export enum AccountStatus {
  Active = 'active',
  Archived = 'archived'
}

export type AuthKeyData = {
  __typename?: 'AuthKeyData';
  authKey: Scalars['String']['output'];
  environment: Scalars['String']['output'];
  origin: Scalars['String']['output'];
  timestamp: Scalars['String']['output'];
  tokenExId: Scalars['String']['output'];
};

export enum ContactStatus {
  Active = 'active',
  Archived = 'archived'
}

/** MutationType */
export type Mutation = {
  __typename?: 'Mutation';
  payAccountAnonymously?: Maybe<PayAccountAnonymouslyOutput>;
  verifyEmail?: Maybe<Scalars['Void']['output']>;
};


/** MutationType */
export type MutationPayAccountAnonymouslyArgs = {
  input: PayAccountAnonymouslyInput;
};


/** MutationType */
export type MutationVerifyEmailArgs = {
  input: VerifyEmailInput;
};

/** Pay Account Anonymously input */
export type PayAccountAnonymouslyInput = {
  accountId: Scalars['String']['input'];
  amountInCents: Scalars['Int']['input'];
  billerSlug: Scalars['String']['input'];
  card: PaymentMethodCardInput;
  email: Scalars['String']['input'];
  sendReceipt: Scalars['Boolean']['input'];
  verificationCode?: InputMaybe<Scalars['String']['input']>;
};

export type PayAccountAnonymouslyOutput = {
  __typename?: 'PayAccountAnonymouslyOutput';
  payment: Payment;
};

/** A payment */
export type Payment = {
  __typename?: 'Payment';
  account?: Maybe<Account>;
  accountId: Scalars['String']['output'];
  amount: Scalars['Float']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  currency: Scalars['String']['output'];
  failedAt?: Maybe<Scalars['DateTime']['output']>;
  gateway: Scalars['String']['output'];
  gatewayTransactionId?: Maybe<Scalars['String']['output']>;
  /** The ID of an object */
  id: Scalars['ID']['output'];
  instalmentId?: Maybe<Scalars['String']['output']>;
  instalmentPlanId?: Maybe<Scalars['String']['output']>;
  mode: PaymentMode;
  paymentMethodId: Scalars['String']['output'];
  processedAt?: Maybe<Scalars['DateTime']['output']>;
  refundStatus?: Maybe<PaymentRefundStatus>;
  refundedAt?: Maybe<Scalars['DateTime']['output']>;
  status: PaymentStatus;
  succeededAt?: Maybe<Scalars['DateTime']['output']>;
  testMode: Scalars['Boolean']['output'];
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  bank?: Maybe<PaymentMethodBank>;
  card?: Maybe<PaymentMethodCard>;
  id: Scalars['String']['output'];
  reason?: Maybe<Scalars['String']['output']>;
  status: PaymentMethodStatus;
  type: PaymentMethodType;
};

export type PaymentMethodBank = {
  __typename?: 'PaymentMethodBank';
  accountName: Scalars['String']['output'];
  accountNumber: Scalars['String']['output'];
  bsb: Scalars['String']['output'];
};

export type PaymentMethodCard = {
  __typename?: 'PaymentMethodCard';
  brand: Scalars['String']['output'];
  expiryMonth: Scalars['Int']['output'];
  expiryYear: Scalars['Int']['output'];
  holderName: Scalars['String']['output'];
  last4: Scalars['String']['output'];
};

export type PaymentMethodCardInput = {
  brand: Scalars['String']['input'];
  expiryMonth: Scalars['Int']['input'];
  expiryYear: Scalars['Int']['input'];
  holderName: Scalars['String']['input'];
  last4: Scalars['String']['input'];
  numberToken: Scalars['String']['input'];
  numberTokenHmac: Scalars['String']['input'];
  referenceNumber: Scalars['String']['input'];
};

export enum PaymentMethodStatus {
  Active = 'active',
  Disabled = 'disabled',
  Removed = 'removed'
}

export enum PaymentMethodType {
  Card = 'card',
  DirectDebit = 'directDebit',
  NzDirectDebit = 'nzDirectDebit'
}

export enum PaymentMode {
  PayAmount = 'payAmount',
  PayInFull = 'payInFull',
  PayInstalment = 'payInstalment',
  PayInstalmentPlanRemainder = 'payInstalmentPlanRemainder'
}

export enum PaymentRefundStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Processing = 'processing',
  Succeeded = 'succeeded'
}

export enum PaymentStatus {
  Cancelled = 'cancelled',
  Failed = 'failed',
  Processing = 'processing',
  Succeeded = 'succeeded'
}

/** QueryType */
export type Query = {
  __typename?: 'Query';
  accountByExternalIdAnonymously?: Maybe<Account>;
  authKeyAnonymously: AuthKeyData;
};


/** QueryType */
export type QueryAccountByExternalIdAnonymouslyArgs = {
  billerSlug: Scalars['String']['input'];
  externalId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  verificationCode?: InputMaybe<Scalars['String']['input']>;
};


/** QueryType */
export type QueryAuthKeyAnonymouslyArgs = {
  billerSlug: Scalars['String']['input'];
};

export type VerifyEmailInput = {
  token: Scalars['String']['input'];
};

export type GetAuthKeyAnonymouslyQueryVariables = Exact<{
  billerSlug: Scalars['String']['input'];
}>;


export type GetAuthKeyAnonymouslyQuery = { __typename?: 'Query', authKeyAnonymously: { __typename?: 'AuthKeyData', authKey: string, tokenExId: string, origin: string, environment: string, timestamp: string } };

export type PayAccountAnonymouslyMutationVariables = Exact<{
  input: PayAccountAnonymouslyInput;
}>;


export type PayAccountAnonymouslyMutation = { __typename?: 'Mutation', payAccountAnonymously?: { __typename?: 'PayAccountAnonymouslyOutput', payment: { __typename?: 'Payment', id: string, mode: PaymentMode, currency: string, amount: number, accountId: string, paymentMethodId: string, testMode: boolean, status: PaymentStatus, refundStatus?: PaymentRefundStatus | null, gateway: string, gatewayTransactionId?: string | null, processedAt?: string | null, succeededAt?: string | null, failedAt?: string | null, refundedAt?: string | null, createdAt?: string | null, account?: { __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, meta: any, collectionExemption?: string | null } | null } } | null };

export type VerifyEmailMutationVariables = Exact<{
  input: VerifyEmailInput;
}>;


export type VerifyEmailMutation = { __typename?: 'Mutation', verifyEmail?: void | null };

export type GetAccountByExternalIdAnonymouslyQueryVariables = Exact<{
  externalId: Scalars['String']['input'];
  type: Scalars['String']['input'];
  billerSlug: Scalars['String']['input'];
  verificationCode?: InputMaybe<Scalars['String']['input']>;
}>;


export type GetAccountByExternalIdAnonymouslyQuery = { __typename?: 'Query', accountByExternalIdAnonymously?: { __typename?: 'Account', id: string, type: string, externalId: string, description: string, amountOwing: number, meta: any, collectionExemption?: string | null, targetDate?: AbsoluteDate | null, issueDate?: AbsoluteDate | null } | null };


export const GetAuthKeyAnonymouslyDocument = gql`
    query getAuthKeyAnonymously($billerSlug: String!) {
  authKeyAnonymously(billerSlug: $billerSlug) {
    authKey
    tokenExId
    origin
    environment
    timestamp
  }
}
    `;

/**
 * __useGetAuthKeyAnonymouslyQuery__
 *
 * To run a query within a React component, call `useGetAuthKeyAnonymouslyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAuthKeyAnonymouslyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAuthKeyAnonymouslyQuery({
 *   variables: {
 *      billerSlug: // value for 'billerSlug'
 *   },
 * });
 */
export function useGetAuthKeyAnonymouslyQuery(baseOptions: Apollo.QueryHookOptions<GetAuthKeyAnonymouslyQuery, GetAuthKeyAnonymouslyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAuthKeyAnonymouslyQuery, GetAuthKeyAnonymouslyQueryVariables>(GetAuthKeyAnonymouslyDocument, options);
      }
export function useGetAuthKeyAnonymouslyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAuthKeyAnonymouslyQuery, GetAuthKeyAnonymouslyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAuthKeyAnonymouslyQuery, GetAuthKeyAnonymouslyQueryVariables>(GetAuthKeyAnonymouslyDocument, options);
        }
export type GetAuthKeyAnonymouslyQueryHookResult = ReturnType<typeof useGetAuthKeyAnonymouslyQuery>;
export type GetAuthKeyAnonymouslyLazyQueryHookResult = ReturnType<typeof useGetAuthKeyAnonymouslyLazyQuery>;
export type GetAuthKeyAnonymouslyQueryResult = Apollo.QueryResult<GetAuthKeyAnonymouslyQuery, GetAuthKeyAnonymouslyQueryVariables>;
export const PayAccountAnonymouslyDocument = gql`
    mutation payAccountAnonymously($input: PayAccountAnonymouslyInput!) {
  payAccountAnonymously(input: $input) {
    payment {
      id
      mode
      currency
      amount
      accountId
      paymentMethodId
      testMode
      status
      refundStatus
      gateway
      gatewayTransactionId
      processedAt
      succeededAt
      failedAt
      refundedAt
      createdAt
      account {
        id
        type
        externalId
        description
        amountOwing
        meta
        collectionExemption
      }
    }
  }
}
    `;
export type PayAccountAnonymouslyMutationFn = Apollo.MutationFunction<PayAccountAnonymouslyMutation, PayAccountAnonymouslyMutationVariables>;

/**
 * __usePayAccountAnonymouslyMutation__
 *
 * To run a mutation, you first call `usePayAccountAnonymouslyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePayAccountAnonymouslyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [payAccountAnonymouslyMutation, { data, loading, error }] = usePayAccountAnonymouslyMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePayAccountAnonymouslyMutation(baseOptions?: Apollo.MutationHookOptions<PayAccountAnonymouslyMutation, PayAccountAnonymouslyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PayAccountAnonymouslyMutation, PayAccountAnonymouslyMutationVariables>(PayAccountAnonymouslyDocument, options);
      }
export type PayAccountAnonymouslyMutationHookResult = ReturnType<typeof usePayAccountAnonymouslyMutation>;
export type PayAccountAnonymouslyMutationResult = Apollo.MutationResult<PayAccountAnonymouslyMutation>;
export type PayAccountAnonymouslyMutationOptions = Apollo.BaseMutationOptions<PayAccountAnonymouslyMutation, PayAccountAnonymouslyMutationVariables>;
export const VerifyEmailDocument = gql`
    mutation verifyEmail($input: VerifyEmailInput!) {
  verifyEmail(input: $input)
}
    `;
export type VerifyEmailMutationFn = Apollo.MutationFunction<VerifyEmailMutation, VerifyEmailMutationVariables>;

/**
 * __useVerifyEmailMutation__
 *
 * To run a mutation, you first call `useVerifyEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyEmailMutation, { data, loading, error }] = useVerifyEmailMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useVerifyEmailMutation(baseOptions?: Apollo.MutationHookOptions<VerifyEmailMutation, VerifyEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyEmailMutation, VerifyEmailMutationVariables>(VerifyEmailDocument, options);
      }
export type VerifyEmailMutationHookResult = ReturnType<typeof useVerifyEmailMutation>;
export type VerifyEmailMutationResult = Apollo.MutationResult<VerifyEmailMutation>;
export type VerifyEmailMutationOptions = Apollo.BaseMutationOptions<VerifyEmailMutation, VerifyEmailMutationVariables>;
export const GetAccountByExternalIdAnonymouslyDocument = gql`
    query getAccountByExternalIdAnonymously($externalId: String!, $type: String!, $billerSlug: String!, $verificationCode: String) {
  accountByExternalIdAnonymously(
    externalId: $externalId
    type: $type
    billerSlug: $billerSlug
    verificationCode: $verificationCode
  ) {
    id
    type
    externalId
    description
    amountOwing
    meta
    collectionExemption
    targetDate
    issueDate
  }
}
    `;

/**
 * __useGetAccountByExternalIdAnonymouslyQuery__
 *
 * To run a query within a React component, call `useGetAccountByExternalIdAnonymouslyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAccountByExternalIdAnonymouslyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAccountByExternalIdAnonymouslyQuery({
 *   variables: {
 *      externalId: // value for 'externalId'
 *      type: // value for 'type'
 *      billerSlug: // value for 'billerSlug'
 *      verificationCode: // value for 'verificationCode'
 *   },
 * });
 */
export function useGetAccountByExternalIdAnonymouslyQuery(baseOptions: Apollo.QueryHookOptions<GetAccountByExternalIdAnonymouslyQuery, GetAccountByExternalIdAnonymouslyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAccountByExternalIdAnonymouslyQuery, GetAccountByExternalIdAnonymouslyQueryVariables>(GetAccountByExternalIdAnonymouslyDocument, options);
      }
export function useGetAccountByExternalIdAnonymouslyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAccountByExternalIdAnonymouslyQuery, GetAccountByExternalIdAnonymouslyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAccountByExternalIdAnonymouslyQuery, GetAccountByExternalIdAnonymouslyQueryVariables>(GetAccountByExternalIdAnonymouslyDocument, options);
        }
export type GetAccountByExternalIdAnonymouslyQueryHookResult = ReturnType<typeof useGetAccountByExternalIdAnonymouslyQuery>;
export type GetAccountByExternalIdAnonymouslyLazyQueryHookResult = ReturnType<typeof useGetAccountByExternalIdAnonymouslyLazyQuery>;
export type GetAccountByExternalIdAnonymouslyQueryResult = Apollo.QueryResult<GetAccountByExternalIdAnonymouslyQuery, GetAccountByExternalIdAnonymouslyQueryVariables>;

import {AbsoluteDatePolicy} from 'payble-graphql-apollo-scalars';

export const scalarTypePolicies = {
  Account: { fields: {issueDate: AbsoluteDatePolicy, targetDate: AbsoluteDatePolicy } },
}