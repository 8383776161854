import React, {useEffect} from 'react';
import {Route, RouteProps, Routes, useLocation} from 'react-router';
import {RouteName} from 'lib/navigation/routes';
import {AutoPayFeatures} from './pages/AutoPayFeatures';
import {BalancedPayFrequency} from './pages/BalancedPayFrequency';
import {SmoothPayFeatures} from './pages/SmoothPayFeatures';
import {SmoothPayAmount} from './pages/SmoothPayAmount';
import {FlexiblePayAmount} from './pages/FlexiblePayAmount';
import {FlexiblePayEveryX} from './pages/FlexiblePayEveryX';
import {
  BalancedPayFeatures,
  FlexiblePayXEveryZFeatures,
  FlexiblePayEveryXFeatures,
} from './pages/FlexiblePayFeatures';
import {FlexiblePayXEveryZ} from './pages/FlexiblePayXEveryZ';
import {FlexiblePaySchedule} from './pages/FlexiblePaySchedule';
import {OneOffAmount} from './pages/OneOffAmount';
import {OneOffOptions} from './pages/OneOffOptions';
import {OneOffSchedule} from './pages/OneOffSchedule';
import {PaymentMethod} from './pages/PaymentMethod';
import {PlanPreview} from './pages/PlanPreview';
import {SetupOptions} from './pages/SetupOptions';
import {SetupSteps} from './components/SetupSteps';
import {Welcome} from './pages/Welcome';
import {SetupRoute} from './components/SetupRoute';
import {ConfirmationContainer} from './pages/ConfirmationContainer';
import {LinkContactInfringement} from './pages/LinkContactInfringement';
import {CatchupFeatures} from './pages/CatchupFeatures';
import {CatchupSchedule} from './pages/CatchupSchedule';
import {RequestPlanFeatures} from './pages/RequestPlanFeatures';
import {RequestPlanRequest} from './pages/RequestPlanRequest';
import {SmoothPaySetup} from './pages/SmoothPaySetup';
import {SmoothPayNext} from './pages/SmoothPayNext';
import {SmoothPayFrequency} from './pages/SmoothPayFrequency';

const BASE_ROUTE: RouteName = '/biller/:slug/setup';
export const ROUTES: RouteProps[] = [
  {
    path: `${BASE_ROUTE}/link-contact/infringement`,
    element: <LinkContactInfringement />,
  },
  {
    path: `${BASE_ROUTE}/options`,
    element: <SetupOptions />,
  },
  {
    path: `${BASE_ROUTE}/payment-method`,
    element: <PaymentMethod />,
  },
  {
    path: `${BASE_ROUTE}/one-off`,
    element: <OneOffOptions />,
  },
  {
    path: `${BASE_ROUTE}/one-off/amount`,
    element: <OneOffAmount />,
  },
  {
    path: `${BASE_ROUTE}/one-off/schedule`,
    element: <OneOffSchedule />,
  },
  {
    path: `${BASE_ROUTE}/auto`,
    element: <AutoPayFeatures />,
  },
  {
    path: `${BASE_ROUTE}/plan/preview`,
    element: <PlanPreview />,
  },
  {
    path: `${BASE_ROUTE}/balanced`,
    element: <BalancedPayFeatures />,
  },
  {
    path: `${BASE_ROUTE}/balanced/frequency`,
    element: <BalancedPayFrequency />,
  },
  {
    path: `${BASE_ROUTE}/smooth`,
    element: <SmoothPayFeatures />,
  },
  {
    path: `${BASE_ROUTE}/catch-up`,
    element: <CatchupFeatures />,
  },
  {
    path: `${BASE_ROUTE}/catch-up/schedule`,
    element: <CatchupSchedule />,
  },
  {
    path: `${BASE_ROUTE}/smooth/setup`,
    element: <SmoothPaySetup />,
  },
  {
    path: `${BASE_ROUTE}/smooth/frequency`,
    element: <SmoothPayFrequency />,
  },
  {
    path: `${BASE_ROUTE}/smooth/next`,
    element: <SmoothPayNext />,
  },
  {
    path: `${BASE_ROUTE}/smooth/amount`,
    element: <SmoothPayAmount />,
  },
  {
    path: `${BASE_ROUTE}/request-plan`,
    element: <RequestPlanFeatures />,
  },
  {
    path: `${BASE_ROUTE}/request-plan/request`,
    element: <RequestPlanRequest />,
  },
  {
    path: `${BASE_ROUTE}/flexible`,
    element: <FlexiblePayXEveryZFeatures />,
  },
  {
    path: `${BASE_ROUTE}/flexible/frequency`,
    element: <FlexiblePayXEveryZ />, // MBRC flex
  },
  {
    path: `${BASE_ROUTE}/flexible-plan`,
    element: <FlexiblePayEveryXFeatures />,
  },
  {
    path: `${BASE_ROUTE}/flexible/installments`,
    element: <FlexiblePayEveryX />, // KCC flex
  },
  {
    path: `${BASE_ROUTE}/flexible/amount`,
    element: <FlexiblePayAmount />,
  },
  {
    path: `${BASE_ROUTE}/flexible/schedule`,
    element: <FlexiblePaySchedule />,
  },
  {
    path: `${BASE_ROUTE}/confirmation`,
    element: <ConfirmationContainer />,
  },
];

/**
 * Based on the route, determine the current step
 */
function getProgress(pathname: string) {
  if (pathname.endsWith('setup')) return 1;
  if (pathname.includes('confirmation')) return 4;
  if (pathname.includes('payment-method')) return 3;
  return 2;
}

export const Setup: React.FC = () => {
  const location = useLocation();

  const pathname = location.pathname;
  const step = getProgress(pathname);

  useEffect(() => {
    document.title = 'Payble - Setup';
  }, [pathname]);

  return (
    <div className="flex flex-col justify-start flex-1 w-full h-full overflow-hidden lg:py-8">
      <SetupSteps step={step} />
      <div className="z-10 flex flex-1 h-full max-w-xl m-auto md:w-xl">
        <Routes>
          <Route
            key={BASE_ROUTE.slice(BASE_ROUTE.length + 1)}
            path={BASE_ROUTE.slice(BASE_ROUTE.length + 1)}
            element={<Welcome />}
          />
          {ROUTES.map(({path, element}) => {
            const relativePath = path?.slice(BASE_ROUTE.length + 1);
            return (
              <Route
                key={relativePath}
                element={<SetupRoute>{element}</SetupRoute>}
                path={relativePath}
              />
            );
          })}
        </Routes>
      </div>
      {/* SVG Dot Pattern */}
      <div className="absolute inset-0 overflow-hidden">
        <svg
          className="absolute inset-0 top-0 overflow-hidden rounded-full left-1/2"
          width={404}
          height={600}
          fill="none"
          viewBox="0 0 404 300"
        >
          <defs>
            <pattern
              id="5d0dd344-b041-4d26-bec4-8d33ea57ec9b"
              x={0}
              y={0}
              width={20}
              height={20}
              patternUnits="userSpaceOnUse"
            >
              <rect
                x={0}
                y={0}
                width={4}
                height={4}
                className="text-gray-200"
                fill="currentColor"
              />
            </pattern>
          </defs>
          <rect
            width={404}
            height={600}
            fill="url(#5d0dd344-b041-4d26-bec4-8d33ea57ec9b)"
          />
        </svg>
      </div>
    </div>
  );
};
