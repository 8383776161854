import {FeatureConfig} from '../schema';

export type PaymentOption = {
  value:
    | 'PAYMENT_MODE'
    | 'PAY_AMOUNT'
    | 'PAY_AUTO'
    | 'PAY_EVERY_X'
    | 'PAY_BALANCED'
    | 'PAY_X_EVERY_Z'
    | 'PAY_X_EVERY_Z'
    | 'PAY_SMOOTH'
    | 'PAY_CATCH_UP';
  label: string;
  description: string;
};

function getBegaOptions(
  accountType: string,
  hasMilestones: boolean
): PaymentOption[] {
  const rateOptions: PaymentOption[] = [];
  if (hasMilestones) {
    rateOptions.push({
      value: 'PAY_BALANCED',
      label: 'Flexible payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    });
  }

  const waterOptions: PaymentOption[] = [
    {
      value: 'PAY_X_EVERY_Z',
      label: 'Flexible payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    },
  ];

  const begaOptions: PaymentOption[] = [
    {
      value: 'PAY_AMOUNT',
      label: 'Make one-off payment',
      description: `Pay now or schedule a one-off payment towards your ${accountType}`,
    },
    {
      value: 'PAY_AUTO',
      label: 'Autopay',
      description: 'Schedule the amount to be paid when payments are due',
    },
    ...(accountType === 'water' ? waterOptions : rateOptions),
  ];

  return begaOptions;
}

function getBerriganOptions(accountType: string): PaymentOption[] {
  const rateOptions: PaymentOption[] = [
    {
      value: 'PAY_EVERY_X',
      label: 'Flexible payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    },
  ];

  const waterOptions: PaymentOption[] = [];

  const berriganOptions: PaymentOption[] = [
    {
      value: 'PAY_AMOUNT',
      label: 'Make one-off payment',
      description: `Pay now or schedule a one-off payment towards your ${accountType}`,
    },
    {
      value: 'PAY_AUTO',
      label: 'Autopay',
      description: 'Schedule the amount to be paid when payments are due',
    },
    ...(accountType === 'water' ? waterOptions : rateOptions),
  ];

  return berriganOptions;
}

function getInfringementsOptions(): PaymentOption[] {
  return [
    {
      value: 'PAYMENT_MODE' as const,
      label: 'Pay in full',
      description:
        'Pay your current infringement in full through a one off payment.',
    },
    {
      value: 'PAY_X_EVERY_Z' as const,
      label: 'Flexible payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    },
  ];
}

function getAllOptions(): PaymentOption[] {
  return [
    {
      value: 'PAY_AMOUNT',
      label: 'Make one-off payment',
      description: 'Pay now or schedule a one-off payment.',
    },
    {
      value: 'PAYMENT_MODE' as const,
      label: 'Pay in full',
      description: 'Pay your current rates in full through a one off payment.',
    },
    {
      value: 'PAY_AUTO' as const,
      label: 'Autopay',
      description: 'Schedule the amount to be paid when payments are due',
    },
    {
      value: 'PAY_EVERY_X' as const,
      label: 'KCC Flexible payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    },
    {
      value: 'PAY_BALANCED' as const,
      label: 'Flexible balanced payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    },
    {
      value: 'PAY_SMOOTH' as const,
      label: 'Smooth instalment payments',
      description: 'Pay weekly, fortnightly & monthly payments',
    },
    {
      value: 'PAY_CATCH_UP' as const,
      label: 'Extended payment plan with interest',
      description:
        'Pay weekly, fortnightly & monthly spread out over the rating period for additional cost',
    },
  ];
}

export const getPaymentOptions = (args: {
  featureConfig: FeatureConfig;
  billerSlug: string;
  accountType: string;
  hasMilestones: boolean;
}): PaymentOption[] => {
  const {accountType, billerSlug, hasMilestones, featureConfig} = args;

  const options: PaymentOption[] = [];

  if (accountType === 'infringements') {
    return getInfringementsOptions();
  }

  if (billerSlug === 'city-of-exampleton') {
    const options = getAllOptions();

    return options;
  }

  if (billerSlug === 'bega-valley-shire') {
    const options = getBegaOptions(accountType, hasMilestones);

    return options;
  }

  if (billerSlug === 'berrigan-shire-council') {
    const options = getBerriganOptions(accountType);

    return options;
  }

  if (billerSlug === 'city-of-kingston') {
    options.push({
      value: 'PAYMENT_MODE',
      label: 'Pay in full',
      description: 'Pay your current rates in full through a one off payment.',
    });
  } else if (featureConfig.ONE_OFF_SCHEDULED) {
    options.push({
      value: 'PAY_AMOUNT',
      label: 'Make one-off payment',
      description: `Pay now or schedule a one-off payment towards your ${accountType}`,
    });
  } else {
    options.push({
      value: 'PAYMENT_MODE',
      label: 'Make one-off payment',
      description: `Make a one-off payment towards your ${accountType}`,
    });
  }

  if (featureConfig.AUTO) {
    options.push({
      value: 'PAY_AUTO',
      label: 'Autopay',
      description: 'Schedule the amount to be paid when payments are due',
    });
  }

  if (featureConfig.BALANCED) {
    if (hasMilestones) {
      options.push({
        value: 'PAY_BALANCED',
        label: 'Flexible payments',
        description: 'Schedule weekly, fortnightly or monthly payments',
      });
    }
  }

  if (featureConfig.PAY_EVERY_X) {
    options.push({
      value: 'PAY_EVERY_X',
      label: 'Flexible payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    });
  }

  if (featureConfig.PAY_X_EVERY_Z) {
    options.push({
      value: 'PAY_X_EVERY_Z',
      label: 'Flexible payments',
      description: 'Schedule weekly, fortnightly or monthly payments',
    });
  }

  if (featureConfig.SMOOTH) {
    if (billerSlug === 'douglas-shire-council') {
      options.push({
        value: 'PAY_SMOOTH',
        label: 'Flexible payments by due date',
        description: 'Schedule weekly, fortnightly or monthly payments',
      });
    } else {
      options.push({
        value: 'PAY_SMOOTH',
        label: 'Flexible payments',
        description: 'Pay weekly, fortnightly & monthly payments',
      });
    }
  }

  if (featureConfig.CATCH_UP) {
    if (billerSlug === 'douglas-shire-council') {
      options.push({
        value: 'PAY_CATCH_UP' as const,
        label: 'Extended payment plan with interest',
        description:
          'Pay weekly, fortnightly & monthly spread out over the rating period for additional cost',
      });
    } else {
      options.push({
        value: 'PAY_CATCH_UP',
        label: 'Flexible payments',
        description: 'Schedule weekly, fortnightly or monthly payments',
      });
    }
  }

  return options;
};
